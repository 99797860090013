// 在您的 Vuex store 中定义一个模块
// 这里假设您已经安装了 Vuex 并且配置了您的 store

// 在 store 文件夹下创建一个新的模块文件 user.js

// user.js

// 导入 Vue 和 Vuex
import Vue from 'vue'
import Vuex from 'vuex'

// 使用 Vuex
Vue.use(Vuex)

// 创建一个 Vuex store 实例
export default new Vuex.Store({
  state: {
    user: null // 初始状态下用户信息为 null
  },
  mutations: {
    // 定义一个 mutation 来设置用户信息
    setUser(state, user) {
      state.user = user
     console.log(state.user);
    },
    // 定义一个 mutation 来清除用户信息，实现登出功能
    clearUser(state) {
      state.user = null
    }
  },
  actions: {
   
  }
})
