import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store' 
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: ()=> import('../views/home.vue'),
    children: [
      {
        path: 'user',
        name: 'UserManagement',
        component: () => import('../views/user.vue')
      },
      {
        path: 'goods',
        name: 'goods',
        component: () => import('../views/goods.vue')
      },
      {
        path: 'goodsdetail/:id',
        name: 'goodsdetail',
        component: () => import('../views/goodsdetail.vue')
      },
      {
        path: 'cate',
        name: 'cate',
        component: () => import('../views/cate.vue')
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/order.vue')
      },
      {
        path: 'pround',
        name: 'pround',
        component: () => import('../views/pround.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
  const user = store.state.user; // 从 Vuex 中获取用户信息
  if (user) {
    // 如果用户信息存在，则允许访问该路由
    next();
  } else {
    // 如果用户信息不存在，则重定向到登录页面
    if (to.path !== '/') {
      next('/');
    } else {
      next();
    }
  }
})
export default router
